<script setup>

import { imaginChangeHost } from "~/utils/imagin";

const props = defineProps({
  photos: {
    type: Array,
    default: [],
  },
  isLightbox: Boolean,
  doNotCut: Boolean
})

const photoIndex = ref(0);
const refThumbnails = ref();
const selectedPhoto = ref('#');

const previous = () => {
  if (photoIndex.value - 1 < 0) {
    photoIndex.value = props.photos.length - 1;
  } else {
    photoIndex.value--;
  }
}

const next = () => {
  if (photoIndex.value + 1 > props.photos.length - 1) {
    photoIndex.value = 0;
  } else {
    photoIndex.value++;
  }
}

watch(
  () => photoIndex.value,
  (newPhotoIndex) => {
    selectedPhoto.value = props.photos[newPhotoIndex];
  }
)

watch(
  () => props.photos,
  () => {
    if (props.photos[0]) selectedPhoto.value = props.photos[0];
  },
  {deep: true, immediate: true}
)

const isLightbox = ref(false);
const emit = defineEmits(['isLightbox'])
const lightboxOpen = () => {
  isLightbox.value = true;
  emit('isLightbox', isLightbox)
}
const { breakpoint } = useBreakpoints();
const calculatedSize = computed(() => {
  switch (breakpoint.value) {
    case 'sm':
      return 64;
    case 'md':
      return 128;
    case 'lg':
      return 128;
    case 'xl':
      return 160;
    case '2xl':
      return 160;
    default:
      return 64;
  }
})
const calculatedStyle = computed(() => {
  switch (breakpoint.value) {
    case 'sm':
      return 'w-[64px] h-[64px]';
    case 'md':
      return 'w-[128px] h-[128px]';
    case 'lg':
      return 'w-[128px] h-[128px]';
    case 'xl':
      return 'w-[160px] h-[160px]';
    case '2xl':
      return 'w-[160px] h-[160px]';
    default:
      return 'w-[64px] h-[64px]';
  }
})

</script>

<template>
  <div>
    <div class="overflow-hidden sm:mx-0" v-if="props.photos.length">
      <div class="mb-1 relative">
        <div class="absolute flex justify-center items-center top-0 h-full w-10 left-0" @click="previous()">
          <div class=" w-8 h-8 bg-white opacity-40 flex justify-center items-center cursor-pointer">
            <IconChevronLeft class="w-2" />
          </div>
        </div>
        <div v-if="isLightbox" class="absolute flex justify-center items-center top-0 h-full w-10 right-0" @click="next()">
          <div class=" w-8 h-8 bg-white opacity-40 flex justify-center items-center cursor-pointer">
            <IconChevronRight class="w-2" />
          </div>
        </div>
        <div @click="lightboxOpen" class="aspect-[3/2] mx-auto overflow-hidden max-h-[608px] w-full object-cover cursor-pointer">
          <div class="w-full h-full bg-center bg-no-repeat" :class="{'bg-cover': !props.doNotCut, 'bg-contain': props.doNotCut}"
              :style="{backgroundImage:'url('+imaginChangeHost(selectedPhoto)+')'}">
          </div>
        </div>
      </div>
        <ScrollBarForSlideControl :scrolledElement="refThumbnails" />
      <div class="-mt-3">
        <SlideControl ref="refThumbnails" :size="props.isLightbox ? calculatedSize : 80" :gap=16>

          <SlideItem v-for="(photo, index) in props.photos" :class="{'!w-20': !props.isLightbox}" class="last:!w-[112px]" custom-size>
            <div :class="[
                  { 'opacity-60': selectedPhoto !== props.photos[index] },
                    props.isLightbox ? calculatedStyle : 'w-20 h-20']"
                  class="bg-cover bg-center bg-no-repeat bg-gray-100 cursor-pointer"
                :style="{backgroundImage:'url('+imaginChangeHost(photo)+')'}" @click="selectedPhoto = props.photos[index]">
            </div>
          </SlideItem>
        </SlideControl>
      </div>
    </div>
  </div>
</template>
